
import { loadingScreenState } from "@/utils/loading-screen";
import { defineComponent, watch } from "vue";

export default defineComponent({
  setup() {
    const modalId = "gLoading";

    watch(
      () => loadingScreenState.show,
      (newVal) => {
        if (newVal) {
          cash(`#${modalId}`).modal("show");
        } else {
          setTimeout(() => {
            cash(`#${modalId}`).modal("hide");
          }, 770);
        }
      }
    );

    return {
      modalId,
      loadingScreenState,
    };
  },
});
